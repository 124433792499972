import styled from 'styled-components'

export const Container = styled.div`
  border-top: 2px solid #fff;
  padding: 3em;
  height: 100px;
  display: grid;
  place-items: center;

  p {
  }
`
